import { APIProvider } from '@vis.gl/react-google-maps'
import React from 'react'
import Carousel from '../components/Carousel'

const Contact = () => {
  return (
    <>
      <div className='container pt-3'>
          <h1 className='text-center display-6 p-4'>Contact Us</h1>
        <div className='row '>
          <div className='col-xs-12 col-sm-6'>
            <h3 className='text-left display-7 mb-4'>Contact Details:</h3>
            <div className='col contact-section'>
              <h3>Better yet, see us in person!</h3>
              <p>
                We love our customers, so feel free to visit during normal business hours.
              </p> 

              <h3>Sofabaazar</h3>
              <p>
                Nawapara, Ambikapur, Chhattisgarh, India
              </p>
              <p>
                Prop. <b>Mahtab Alam</b><br/>
                Ph: +91 97542 45504 <br/>
                Email: <a href="mailto:info@sofabaazar.com">info@sofabaazar.com</a>
              </p>

              <h3>Hours</h3>
              <p>Mon to Sun 09:00 am - 07:00 pm</p>
              <p>Closed on Tuesday</p>
            </div>         
          </div>
          <div className='col-xs-12 col-sm-6'>
              <h3 className='text-left display-7'>Map</h3>

              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2990.274257380938!2d-70.56068388481569!3d41.45496659976631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e52963ac45bbcb%3A0xf05e8d125e82af10!2sDos%20Mas!5e0!3m2!1sen!2sus!4v1671220374408!5m2!1sen!2sus" width="100%" height="350"  loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact

import React from 'react'
import sofa1 from '../assets/images/sofa1.jpeg';
import sofa2 from '../assets/images/sofa2.jpg';
import { carouselImages } from '../data/data';

type caraouselProps ={
  carausel:{}[]
}
const Carousel = ({carausel}:caraouselProps) => {
  return (
    <div id="carouselExampleCaptions" className="carousel slide">
  <div className="carousel-indicators">
    {
      carausel.map((item:any, index)=>(
        <button type="button" key={index} data-bs-target="#carouselExampleCaptions" data-bs-slide-to={index} className={index === 0 ? 'active' : ''} aria-current="true" aria-label={`Slide ${index}`}></button>
      ))
    }
    {/* <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
  </div>
  <div className="carousel-inner">
    {
      carausel.map((item:any, index)=>(
        <div className={`carousel-item ${index === 0 ? 'active' : ''}`}>
        <img src={item.image} key={item.image} className="d-block w-100" alt={item.image} />
        <div className="carousel-caption d-none d-md-block">
          <h5>{item.caption}</h5>
          <p>{item.description}</p>
        </div>
      </div>
      ))
    }
    {/* <div className="carousel-item active">
      <img src={sofa1} className="d-block w-100" alt="..." />
      <div className="carousel-caption d-none d-md-block">
        <h5>First slide label</h5>
        <p>Some representative placeholder content for the first slide.</p>
      </div>
    </div>
    <div className="carousel-item">
      <img src={sofa2} className="d-block w-100" alt="..." />
      <div className="carousel-caption d-none d-md-block">
        <h5>Second slide label</h5>
        <p>Some representative placeholder content for the second slide.</p>
      </div>
    </div>
    <div className="carousel-item">
      <img src={sofa1} className="d-block w-100" alt="..." />
      <div className="carousel-caption d-none d-md-block">
        <h5>Third slide label</h5>
        <p>Some representative placeholder content for the third slide.</p>
      </div>
    </div> */}
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
  )
}

export default Carousel

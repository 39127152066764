export const galleryHome = [
        {
            "imageName":require("../assets/images/gallary/Img1.jpeg"),
            "title": "Img1.jpg"
        },
        {
            "imageName":require("../assets/images/gallary/Img2.jpeg"),
            "title": "Img2.jpg"
        },
        {
            "imageName":require("../assets/images/gallary/Img3.jpeg"),
            "title": "Img3.jpg"
        },
        {
            "imageName":require("../assets/images/gallary/Img4.jpeg"),
            "title": "Img4.jpg"
        },
        {
            "imageName":require("../assets/images/gallary/Img5.jpeg"),
            "title": "Img5.jpg"
        },
        {
            "imageName":require("../assets/images/gallary/Img6.jpeg"),
            "title": "Img6.jpg"
        },
        {
            "imageName":require("../assets/images/gallary/Img7.jpeg"),
            "title": "Img7.jpg"
        }
    
]

export const imageList =[
    {
        "imageName":require("../assets/images/gallary/Img1.jpeg"),
        "title": "Img1.jpg"
    },
    {
        "imageName":require("../assets/images/gallary/Img2.jpeg"),
        "title": "Img2.jpg"
    },
    {
        "imageName":require("../assets/images/gallary/Img3.jpeg"),
        "title": "Img3.jpg"
    },
    {
        "imageName":require("../assets/images/gallary/Img4.jpeg"),
        "title": "Img4.jpg"
    },
    {
        "imageName":require("../assets/images/gallary/Img5.jpeg"),
        "title": "Img5.jpg"
    },
    {
        "imageName":require("../assets/images/gallary/Img6.jpeg"),
        "title": "Img6.jpg"
    },
    {
        "imageName":require("../assets/images/gallary/Img7.jpeg"),
        "title": "Img7.jpg"
    },
    {
        "imageName":require("../assets/images/gallary/Img8.jpeg"),
        "title": "Img8.jpg"
    },
    {
        "imageName":require("../assets/images/gallary/Img9.jpeg"),
        "title": "Img9.jpg"
    },
    {
        "imageName":require("../assets/images/gallary/Img10.jpeg"),
        "title": "Img10.jpg"
    },
    {
        "imageName":require("../assets/images/gallary/Img11.jpeg"),
        "title": "Img11.jpg"
    },
    {
        "imageName":require("../assets/images/gallary/Img12.jpeg"),
        "title": "Img12.jpg"
    },
    {
        "imageName":require("../assets/images/gallary/Img13.jpeg"),
        "title": "Img13.jpg"
    },
    {
        "imageName":require("../assets/images/gallary/Img14.jpeg"),
        "title": "Img14.jpg"
    },
    {
        "imageName":require("../assets/images/gallary/Img15.jpeg"),
        "title": "Img15.jpg"
    }
    
]

export const carouselImages = [
    {
        "image": require("../assets/images/gallary/Img8.jpeg"),
        "caption": "Customized Sofa",
        "description": "we customize the sofa based on the customer requirement and place"
    },
    {
        "image": require("../assets/images/gallary/Img9.jpeg"),
        "caption": "Customized Sofa",
        "description": "we customize the sofa based on the customer requirement and space"
    },
    {
        "image": require("../assets/images/gallary/Img7.jpeg"),
        "caption": "Customized Sofa",
        "description": "we customize the sofa based on the customer requirement and space"
    }
]
import React from 'react';
import {NavLink} from 'react-router-dom';
import logo from '../assets/images/logo-no-background.png';
import '../styles/navbar.scss';

const menuList = ["Home", "Gallary", "About-us", "Contact-us"];


const Header = () => {
  const activeMenu = window.location.origin.includes('Home')
  return (
      <header>
        <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
            <div className="container-fluid">
            <NavLink className="navbar-brand" to="home">
                <img src={logo} alt='logo' style={{width:'90px', height:'auto'}}/>
            </NavLink>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <ul className="navbar-nav me-auto mb-2 mb-md-0">
                    {
                        menuList.map((item=>(
                            <li>
                                <NavLink  to={`/${item.toLowerCase()}`} key={item} className={`nav-link ${window.location.origin.includes(item) ? 'active' : ''}`}  aria-current="page" >{item}</NavLink>
                            </li>
                        )
                        ))
                    }
              
                </ul>
            </div>
            </div>
        </nav>
        </header>
  )
}

export default Header

import React from 'react'
import sofa1 from '../assets/images/sofa1.jpeg';
import sofa2 from '../assets/images/sofa2.jpg';
import '../styles/gallary.css';
import { imageList } from '../data/data';

type ImageListProp ={
  imgList:{}[]
}
console.log(imageList);

const Gallary = ({imgList}:ImageListProp) => {
  return (
    <>
      <div className='container mb-3'>
        <div className='row'>
          <div className='col'>
            <div className="photo-gallery">
              <div className="container">
                <div className="intro">
                  <h1 className='display-6 p-5 text-center'>Explore Our Stunning Furniture Collection</h1>
                </div>
                <div className="row photos">
                  {
                    imgList.map((item:any)=>(

                      <div className="col-sm-4 col-xs-12 item" key={item.imageName}><a href={item.imageName} data-lightbox="photos" ><img className="img-fluid" src={item.imageName} /></a>
                        
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default Gallary

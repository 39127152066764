import React from 'react'

import sofa1 from '../assets/images/sofa1.jpeg';
import sofa2 from '../assets/images/sofa2.jpg';

const About = () => {
  return (
    <>
    <div className='container-fluid p-3 bg-milkshake'>
                <div className='row p-5'>
                    <div className='d-flex justify-content-center align-items-center flex-column'>
                        <h1 className='display-6 p-2 mt-2 text-center'>Modern and Affordable Furniture</h1>
                        <span className='display-7 text-center'>Shop our wide selection of stylish furniture for any budget</span>
                    </div>
                </div>
    </div>
    <div className='container'>
                <h1 className='display-6 p-4 mt-4 text-center'>Discover Sofabaazar</h1>
                <div className="row">
                    <div className='col'>
                        <div className="d-md-flex flex-md-equal w-100 my-md-3 ps-md-3">
                            <div className="bg-dark me-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden">
                                <div className="my-3 py-3">
                                    <h2 className="display-5">Our Story</h2>
                                    <p className="lead">At Sofabaazar, we are a family-owned business that has been providing high-quality furniture to our customers for over 25 years. Our passion for creating beautiful and functional spaces has driven us to become one of the leading furniture stores in the area.</p>
                                </div>
                                <div className="bg-light shadow-sm mx-auto" >
                                    <img src={sofa1} className="d-block w-100" alt="..." />
                                </div>
                            </div>
                            <div className="bg-light me-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
                                <div className="my-3 p-3">
                                    <h2 className="display-5">Our Philosphy</h2>
                                    <p className="lead">We believe that furniture should not only be beautiful but also functional. Our products are carefully selected to provide our customers with a wide range of options that are both stylish and practical for everyday use..</p>
                                </div>
                                <div className="bg-dark shadow-sm mx-auto" >
                                    <img src={sofa2} className="d-block w-100" alt="..." />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col'>
                        <div className="d-md-flex flex-md-equal w-100 my-md-3 ps-md-3">
                            <div className="bg-dark me-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden">
                                <div className="my-3 py-3">
                                    <h2 className="display-5">Our Story</h2>
                                    <p className="lead">At Sofabaazar, we are a family-owned business that has been providing high-quality furniture to our customers for over 25 years. Our passion for creating beautiful and functional spaces has driven us to become one of the leading furniture stores in the area.</p>
                                </div>
                                <div className="bg-light shadow-sm mx-auto" >
                                    <img src={sofa1} className="d-block w-100" alt="..." />
                                </div>
                            </div>
                            <div className="bg-light me-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
                                <div className="my-3 p-3">
                                    <h2 className="display-5">Our Designers</h2>
                                    <p className="lead">Our team of talented designers are dedicated to helping you create the perfect space for your home. We offer a range of design services, from a simple room refresh to a complete home makeover.</p>
                                </div>
                                <div className="bg-dark shadow-sm mx-auto" >
                                    <img src={sofa2} className="d-block w-100" alt="..." />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
    
  )
}

export default About

import React from 'react'

const Footer = () => {
  return (
  <footer className="container-fluid">
    <p style={{margin:0}}><span>Copyright © 2024 Sofabaazar - All Rights Reserved.</span></p>
    <p style={{margin:0}}><span>Developed by: ARahim</span></p>
  </footer>
  )
}

export default Footer

import React from 'react';
import './App.scss';
import Header from './components/Header';
import Footer from './components/Footer';
import {Outlet} from 'react-router-dom';
import Carousel from './components/Carousel';
import { carouselImages } from './data/data';

function App() {
  return (
    <>    
      <Header />
    <main className="add-top">
      <Carousel carausel={carouselImages}/>      
      <Outlet></Outlet>
    </main>
      <Footer />
    </>
  );
}

export default App;
